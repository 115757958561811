<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card v-if="formDetails">
      <h1>ویرایش فرم</h1>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="نام فرم" label-for="name">
              <b-form-input
                id="name"
                trim
                placeholder="تماس با ما یا ..."
                v-model="formDetails.name"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <language-picker
            @getLanguage="setLanguage"
            :defaultLanguageId="formDetails.languageId"
          ></language-picker>
        </b-col>
        <b-col cols="12" md="6">
          <page-picker
            @getPage="setPage"
            :defaultPageId="formDetails.pageId"
          ></page-picker>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="توضیحات فرم" label-for="description">
              <b-form-textarea
                id="description"
                v-model="formDetails.description"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-button variant="primary" class="" @click="renderMediaPicker">
            {{
              formDetails.formPDF
                ? "ویرایش PDF برای فرم"
                : "انتخاب PDF برای فرم"
            }}
          </b-button>

          <a
            target="_blank"
            :href="formDetails.formPDF"
            v-if="formDetails.formPDF"
            class="ml-2 btn btn-success"
          >
            مشاهده PDF کنونی
          </a>
        </b-col>
      </b-row>
      <hr class="my-2" />
      <h2>افزودن فیلد جدید به فرم</h2>
      <hr />
      <b-row>
        <b-col cols="12">
          <form-input-picker @getFormInput="setFormInput"></form-input-picker>
        </b-col>
        <hr />
        <b-col cols="12">
          <h2 v-if="formDetails.formSelectedInputs.length > 0">
            جزئیات فیلد ها
          </h2>
        </b-col>
        <b-col cols="12">
          <div
            class="w-100"
            v-for="(form, index) in formDetails.formSelectedInputs"
            :key="index"
          >
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group :label-for="`formtype-${index}`" label="نوع فرم">
                  <form-input-picker
                    :placeholder="''"
                    :isRenderingButton="false"
                    :defaultFormInputId="form.formInputId"
                    @getFormInput="setThisFormInput(form, $event)"
                  ></form-input-picker>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" v-if="doesFormHasValues(form)">
                <b-form-group
                  :label-for="`hasValue-${index}`"
                  label="فیلد دارای مقدار است؟"
                >
                  <b-form-checkbox
                    :id="`hasValue-${index}`"
                    v-model="form.hasValue"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col cols="5" md="3">
                <b-form-group
                  :label-for="`isRequired-${index}`"
                  label="فیلد اجباری است؟"
                >
                  <b-form-checkbox
                    :id="`isRequired-${index}`"
                    v-model="form.isRequired"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col cols="1" align-self="center">
                <feather-icon
                  size="20"
                  icon="Trash2Icon"
                  class="cursor-pointer text-danger"
                  @click="deleteFormInput(form)"
                />
              </b-col>
              <b-col cols="12" md="6">
                <!-- Here -->
                <b-form-group :label-for="`label-${index}`" label="برچسب فیلد">
                  <b-form-input
                    placeholder="برای مثال: 'نام خود را وارد کنید'"
                    :id="`label-${index}`"
                    v-model="form.label"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label-for="`tooltip-${index}`"
                  label="راهنمای فیلد"
                >
                  <b-form-input
                    placeholder="برای مثال: 'نام شرکت یا دانشگاه جهت صدور صورت حساب'"
                    :id="`tooltip-${index}`"
                    v-model="form.tooltip"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.hasValue">
              <b-col cols="12">
                <div
                  class="w-100 d-flex align-items-center justify-content-between"
                >
                  <h3>مقادیر فیلد {{ inputTypes[form.type] }}</h3>
                  <b-button
                    variant="transparent"
                    @click="addNewFormValue(form)"
                  >
                    <feather-icon
                      size="20"
                      icon="PlusCircleIcon"
                      class="text-success"
                    />
                  </b-button>
                </div>
                <hr />
              </b-col>
              <template v-if="form.formSelectedInputValues.length > 0">
                <template
                  v-for="(formInput, index) in form.formSelectedInputValues"
                >
                  <b-col cols="11" :key="index + 1000" align-self="center">
                    <b-form @submit.prevent>
                      <b-form-group label="مقدار فیلد" label-for="value">
                        <b-form-input
                          id="value"
                          v-model="formInput.value"
                        ></b-form-input>
                      </b-form-group>
                    </b-form>
                  </b-col>
                  <b-col cols="1" :key="index + 10000">
                    <b-button
                      variant="transparent"
                      @click="deleteFormField(form, formInput)"
                    >
                      <feather-icon
                        size="20"
                        icon="Trash2Icon"
                        class="cursor-pointer text-danger"
                      />
                    </b-button>
                  </b-col>
                </template>
              </template>
            </b-row>
            <hr />
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="updateForm"> ذخیره </b-button>
      </div>
      <library-image-picker @insertImage="setFormPdf"></library-image-picker>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "ویرایش فرم",
  mounted() {
    this.getASingleForm();
  },
  data() {
    return {
      isLoading: false,
      formDetails: null,
      newFormSelectedInput: [],
      inputTypes: {
        CustomFile: "فایل",
        CustomTextarea: "متن بزرگ",
        CustomText: "متن",
        CustomSelect: "لیست",
        CustomRadio: "رادیو",
        CustomCheckbox: "چند گزینه‌ای",
      },
      formsThatHasValue: ["CustomRadio", "CustomCheckbox", "CustomSelect"],
    };
  },
  methods: {
    setFormPdf(pdfUrl) {
      this.formDetails.formPDF = pdfUrl;
    },
    renderMediaPicker() {
      this.$bvModal.show("library-image-picker-modal");
    },
    deleteFormField(form, formInput) {
      form.formSelectedInputValues = form.formSelectedInputValues.filter(
        (item) => item != formInput
      );
    },
    addNewFormValue(form) {
      form.formSelectedInputValues.push({
        isDeleted: false,
        formSelectedInputValueId: 0,
        formSelectedInputId: form.formSelectedInputId,
        value: null,
        createDate: new Date(Date.now()).toISOString(),
        priority: 1,
      });
    },
    deleteFormInput(form) {
      this.formDetails.formSelectedInputs =
        this.formDetails.formSelectedInputs.filter((item) => item != form);
    },
    doesFormHasValues(form) {
      if (this.formsThatHasValue.includes(form.type)) {
        return true;
      }
      return false;
    },
    setThisFormInput(form, formType) {
      form.formInputId = formType.formInputId;
      form.type = formType.type;
      if (!form.formSelectedInputValues) {
        form.formSelectedInputValues = [];
      }
    },
    setFormInput(formType) {
      this.formDetails.formSelectedInputs.push({
        isDeleted: false,
        formSelectedInputId: 0,
        formInputId: formType.formInputId,
        formId: this.formDetails.formId,
        priority: 1,
        createDate: new Date(Date.now()).toISOString(),
        hasValue: false,
        isRequired: false,
        label: null,
        tooltip: null,
        type: formType.type,
        formSelectedInputValues: [],
      });
    },
    // This method is not needed in this page, remove later
    async updateForm() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASingleForm = new UpdateASingleForm(_this);
        updateASingleForm.setRequestParamDataObj(_this.formDetails);
        await updateASingleForm.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فرم موفقیت ویرایش شد.`,
              },
            });
            _this.getASingleForm();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getASingleForm() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleForm = new GetASingleForm(_this);
        getASingleForm.setRequestParam({ id: _this.$route.params.id });
        await getASingleForm.fetch((response) => {
          if (response.isSuccess) {
            _this.formDetails = JSON.parse(JSON.stringify(response.data));
            // _this.newFormSelectedInput = response.data.formSelectedInputs;
            // _this.newFormSelectedInput.forEach((item) => {
            //   item["isUpdating"] = true;
            // });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    removeTypeFromClonedFormSelectedInput(clonedForm) {
      // I add type for personal use in ui, i'm deleting from the clone in here
      clonedForm.formSelectedInputs.forEach((item) => {
        delete item.type;
      });
      return clonedForm;
    },
    setLanguage(language) {
      this.formDetails.languageId = language.languageId;
    },
    setPage(page) {
      this.formDetails.pageId = page.pageId;
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    LanguagePicker,
    PagePicker,
    FormInputPicker,
    LibraryImagePicker,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormRadio,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import LibraryImagePicker from "@/views/components/utils/LibraryImagePicker.vue";

import {
  GetASingleForm,
  UpdateASingleForm,
  CompletelyCreateForm,
} from "@/libs/Api/Form";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import PagePicker from "@/views/components/utils/PagePicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FormInputPicker from "@/views/components/utils/FormInputPicker.vue";
</script>
